/*	
 *	-- Components --
 *	Scripts concatenated and placed before document closing </body>
 */


var thirst = thirst || {};

// Components
(function ($) {

	// import('components/_autocomplete.js');

	$(document).ready(function () {

		thirst.autocomplete.init();

	});

})(jQuery);